import React, { useState } from 'react';

import { useRouter } from 'next/router';

import { Spin } from 'antd';

import { getTariffs } from '@/api/tariff/get-tariffs';
import useAsyncEffect from '@/hooks/use-async-effect';
import useMobile from '@/hooks/use-mobile';
import { Tariff } from '@/types/tariff';

import { TariffsTableDesktop } from './TariffsTableDesktop';
import { TariffsTableMobile } from './TariffsTableMobile';
import { TariffsTableProps } from './types';

import styles from './TariffsTable.module.scss';

export default function TariffsTable(props: TariffsTableProps) {
	const router = useRouter();
	const isMobile = useMobile();
	const [tariffsInfoData, setTariffsInfoData] = useState<Tariff.TariffsInfoResponse>(
		props.tariffsData,
	);
	const [isLoadingTariffs, setIsLoadingTariffs] = useState(true);

	// Получает тарифы
	useAsyncEffect(async () => {
		try {
			if (!props.tariffsData) {
				setIsLoadingTariffs(true);
				setTariffsInfoData(await getTariffs({ lang: router.locale }));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoadingTariffs(false);
		}
	}, []);

	if (isLoadingTariffs) {
		return (
			<div className={styles.spiner}>
				<Spin />
			</div>
		);
	}

	return isMobile ? (
		<TariffsTableMobile {...props} tariffsInfoData={tariffsInfoData} />
	) : (
		<TariffsTableDesktop {...props} tariffsInfoData={tariffsInfoData} />
	);
}
